<template>
  <div class="jsgl-page">
    <!-- 网络配置 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-select v-model="queryParams.schoolId" clearable placeholder="请选择学校" @change="refresh">
          <el-option v-for="item in schoolSelectOptions" :key="item.id" :label="item.extend.schoolName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.wifiName" clearable placeholder="请输入wifi名称" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh" >搜索</el-button>
      </el-form-item>
      <el-form-item class="fr">
        <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false,editSchool())" v-if="OA.includes('service:netConfig:add')" >添加</el-button>
      </el-form-item>
    </el-form>

    <div class="content-wrap">
      <!--列表-->
      <paged-table :data="netConfigList" :total="netConfigTotal" :loading="tLoading" :isShowSelection="false" defaultSortProp="id" v-model="queryParams" @refresh="refresh">
        <el-table-column prop="extend.schoolName" show-overflow-tooltip label="学校名称"></el-table-column>
        <el-table-column prop="wifiName" show-overflow-tooltip label="wifi名称"></el-table-column>
        <el-table-column prop="cityhotOnline" show-overflow-tooltip label="城市热点"></el-table-column>
        <el-table-column prop="serverIp" show-overflow-tooltip label="热点ip"></el-table-column>
        <el-table-column prop="equiplink" show-overflow-tooltip label="内网ip"></el-table-column>
        <el-table-column prop="citySbname" show-overflow-tooltip label="终端编号"></el-table-column>
        <el-table-column prop="cityhotLogin" show-overflow-tooltip label="登录地址"></el-table-column>
        <el-table-column prop="cityhotExit" show-overflow-tooltip label="注销地址"></el-table-column>
        <el-table-column prop="netType" show-overflow-tooltip label="网络类型">
          <template slot-scope="scope">
            <span>{{ scope.row.netType == 1 ? "公网" : "内网" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="apiVersion" show-overflow-tooltip label="api版本号"></el-table-column>
        <el-table-column prop="signKey" show-overflow-tooltip label="密钥"></el-table-column>
        <el-table-column prop="joinDate" show-overflow-tooltip label="加入时间"></el-table-column>
        <el-table-column align="right" label="操作" width="110" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button size="mini" type="primary" @click="handleForm(true, scope.row)" icon="el-icon-edit" title="编辑" v-if="OA.includes('service:netConfig:editor')"></el-button>
            <el-button size="mini" type="danger" @click="handleDelete({ id: scope.row.id })" icon="el-icon-delete" v-if="OA.includes('service:netConfig:del')" title="删除"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑网络配置' : '添加网络配置'" :visible.sync="aVisible" width="550px" :close-on-click-modal="false">
      <el-form label-width="150px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="学校：" prop="schoolId">
          <el-select v-model="form.schoolId" clearable placeholder="请选择需要配置网络的学校">
            <el-option v-for="item in schoolEditOptions" :key="item.id" :label="item.schoolName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="加入时间：" prop="joinDate">
          <el-date-picker style="width:100%" v-model="form.joinDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择加入时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="wifi名称：" prop="wifiName">
          <el-input v-model="form.wifiName" placeholder="请输入wifi名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="内网ip：" prop="equiplink">
          <el-input v-model="form.equiplink" placeholder="请输入内网ip" clearable></el-input>
        </el-form-item>
        <el-form-item label="登录地址：" prop="cityhotLogin">
          <el-input v-model="form.cityhotLogin" placeholder="请输入登录地址" clearable></el-input>
        </el-form-item>
        <el-form-item label="注销地址：" prop="cityhotExit">
          <el-input v-model="form.cityhotExit" placeholder="请输入注销地址" clearable></el-input>
        </el-form-item>
        <el-form-item label="城市热点：" prop="cityhotOnline">
          <el-input v-model="form.cityhotOnline" placeholder="请输入城市热点" clearable></el-input>
        </el-form-item>
        <el-form-item label="热点ip：" prop="serverIp">
          <el-input v-model="form.serverIp" placeholder="请输入热点ip" clearable></el-input>
        </el-form-item>
        <el-form-item label="终端编号：" prop="citySbname">
          <el-input v-model="form.citySbname" placeholder="请输入终端编号" clearable></el-input>
        </el-form-item>
        <el-form-item label="网络类型：" prop="netType">
          <el-radio-group v-model="form.netType">
            <el-radio :label="1">公网</el-radio>
            <el-radio :label="2">内网</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="api版本号：" prop="apiVersion">
          <el-input v-model="form.apiVersion" placeholder="请输入api版本号" clearable></el-input>
        </el-form-item>
        <el-form-item label="密钥：" prop="signKey">
          <el-input v-model="form.signKey" placeholder="请输入密钥" clearable></el-input>
        </el-form-item>
        <el-form-item label="城市热点连接前缀：" prop="strCitylink">
          <el-input v-model="form.strCitylink" placeholder="请输入城市热点连接前缀" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)" >保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="网络配置详情" :visible.sync="bVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="学校名称：" prop="schoolName">
          <span v-text="details.extend.schoolName"></span>
        </el-form-item>
        <el-form-item label="wifi名称：" prop="wifiName">
          <span v-text="details.wifiName"></span>
        </el-form-item>
        <el-form-item label="城市热点：" prop="cityhotOnline">
          <span v-text="details.cityhotOnline"></span>
        </el-form-item>
        <el-form-item label="热点ip：" prop="serverIp">
          <span v-text="details.serverIp"></span>
        </el-form-item>
        <el-form-item label="内网ip：" prop="equiplink">
          <span v-text="details.equiplink"></span>
        </el-form-item>
        <el-form-item label="终端编号：" prop="citySbname">
          <span v-text="details.citySbname"></span>
        </el-form-item>
        <el-form-item label="登录地址：" prop="cityhotLogin">
          <span v-text="details.cityhotLogin"></span>
        </el-form-item>
        <el-form-item label="注销地址：" prop="cityhotExit">
          <span v-text="details.cityhotExit"></span>
        </el-form-item>
        <el-form-item label="网络类型：" prop="netType">
          <span v-if="details.netType ==1">公网</span>
          <span v-else>内网</span>
        </el-form-item>
        <el-form-item label="api版本号：" prop="apiVersion">
          <span v-text="details.apiVersion"></span>
        </el-form-item>
        <el-form-item label="密钥：" prop="signKey">
          <span v-text="details.signKey"></span>
        </el-form-item>
        <el-form-item label="加入时间：" prop="joinDate">
          <span v-text="details.joinDate"></span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from "@/common/utils";
export default {
  name: "netConfig",
  components: {
    PagedTable,
  },
  mixins: [pageMixin],
  data() {
    return {
      schoolSelectOptions: [],
      schoolEditOptions: [],
      queryParams: {
        schoolId: null,
        wifiName: '',
      },

      form: {
        id: null,
        schoolId: null,
        wifiName: '',
        joinDate: "",
        strCitylink: "",
        equiplink: "",
        cityhotLogin: "",
        cityhotExit: '',
        cityhotOnline: '',
        serverIp: '',
        citySbname: '',
        netType: '',
        apiVersion: '',
        signKey: '',
      },
      details: {
        extend: {},
        wifiName: "",
        cityhotOnline: "",
        serverIp: '',
        equiplink: '',
        citySbname: '',
        cityhotLogin: '',
        cityhotExit: '',
        netType: '',
        apiVersion: '',
        signKey: '',
        joinDate: '',
      },
      formRules: {
        schoolId: [{ required: true, message: "请选择需要配置网络的学校", trigger: "blur" }],
        joinDate: [{ required: true, message: "选择加入时间", trigger: "blur" }],
        wifiName: [{ required: true, message: "请输入wifi名称", trigger: "blur" }],
        equiplink: [{ required: true, message: "请输入内网ip", trigger: "blur" }],
        cityhotLogin: [{ required: true, message: "请输入登录地址", trigger: "blur" }],
        cityhotExit: [{ required: true, message: "请输入注销地址", trigger: "blur" }],
        cityhotOnline: [{ required: true, message: "请输入城市热点", trigger: "blur" }],
        serverIp: [{ required: true, message: "请输入热点ip", trigger: "blur" }],
        citySbname: [{ required: true, message: "请输入终端编号", trigger: "blur" }],
        netType: [{ required: true, message: "请输入网络类型", trigger: "blur" }],
        apiVersion: [{ required: true, message: "请输入api版本号", trigger: "blur" }],
        signKey: [{ required: true, message: "请输入密钥", trigger: "blur" }],
        strCitylink: [{ required: true, message: "请输入城市热点连接前缀", trigger: "blur" }],
      },
      pageApi: "netAdminSchoolNetList",
      insertApi: "adminSchoolNetAdd",
      editorApi: "adminSchoolNetEdit",
      deleteApi: "adminSchoolNetDelete",
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("netConfig", ["netConfigList", "netConfigTotal"]),
  },
  methods: {
    ...mapActions("netConfig", [
      "netAdminSchoolNetList",
      "netAdminSchoolNetAll",
      "adminSchoolNetAdd",
      "adminSchoolNetLoad",
      "adminSchoolNetEdit",
      "adminSchoolNetDelete",
      "membersSchoolSelectAll",
    ]),    
    async selectSchool(){
      let res = await this.netAdminSchoolNetAll({})
      this.schoolSelectOptions = res
    },
    async editSchool(){
      let res = await this.membersSchoolSelectAll({wifi: 1})
      this.schoolEditOptions = res
    }
  },
  async mounted() {
    if (this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name));
    this.refresh();
    this.selectSchool()
  },
};
</script>

<style lang="scss">
.jsgl-page {
    .el-tree-node__content:hover {
        background-color: #fff;
    }
    .el-tree-node__children {
        display: flex !important;
        flex-wrap: wrap;
        .el-tree-node {
            display: inline-block;
            .el-tree-node__children {
                display: block !important;
                flex-wrap: normal;
                .el-tree-node {
                    display: block;
                }
            }
        }
    }
    .right { text-align:right; margin-top:30px; }
}
</style>